/** @typedef {import("overline").Deferred} Deferred */

import zipWith from "lodash/zipWith";
import { downloadURL } from "downloaders";
// import { exportPDF } from "src/extensions/export-job/pdf.js"
import { drawingsToDXM, createDrawingSet } from "src/extensions/converters/dxm";
import itemTagGenerator from "@local/extensions/utilities/item-tag.js";
import { createFeatureFlags } from "@local/lamina-core";
import {
  VANDELAY_URL,
  CORVELAY_PUBLIC_URL,
  CORVELAY_PRIVATE_URL,
  DEFAULT_LOG_LEVEL,
} from "src/env";
import { api } from "src/api";
import { getLogger } from "@diagraphics/logging";
import { count, identity } from "ramda";

const log = getLogger(Symbol("download-job-bundle"), DEFAULT_LOG_LEVEL, true);

export async function createDXF(group) {
  const drawings = drawingsToDXM(group);
  return await createDrawingSet(drawings);
}

function archiveFilenameResolver(bucket, prefix) {
  /** @type {(x?: any) => string} */
  let resolveDirname = () => bucket;

  if (typeof prefix === "function") {
    resolveDirname = prefix;
  } else if (typeof prefix === "string") {
    resolveDirname = () => prefix;
  }

  return function resolveArchiveFilename(item) {
    const basename = item.path.split("/").pop();
    const dirname = resolveDirname(item.path);
    return `${dirname}/${basename}`;
  };
}

async function createSignedURLs(bucket, paths, prefix) {
  let members = {};

  if (paths.length > 0) {
    const { data, error } = await api.storage
      .from(bucket)
      .createSignedUrls(paths, 120);

    if (error || data["error"]) {
      log.warn(
        `[download-job-bundle] Error obtaining signed URLs for bucket "${bucket}"`,
      );
    } else {
      log.debug(`[download-job-bundle] Signed urls: %o`, data);

      /* This is hack to account for a discrepancy in return values between dev and production
       * At the time of writing, in production we get an array of objects with a "path" and
       * "signedUrl" property, while in development we only get a "signedUrl" property.
       *
       * Therefore, we check for the existence of the "path" property, and if it is not present
       * then we zip the signedUrls with the paths we provided in the request. Let's hope that
       * the API always returns the URLs in the same order as the provided paths!
       *
       * For further details see:
       *
       * https://github.com/supabase/storage-js/issues/169
       * https://github.com/supabase/storage-js/issues/169
       * https://diagraphics.slack.com/archives/C030GPLTNNR/p1690500911464139
       *
       * TODO: remove this hack once the API and client code stablize
       *
       */

      const items = data.every((item) => "path" in item)
        ? data
        : zipWith(paths, data, (path, { signedUrl }) => ({ path, signedUrl }));

      const resolveArchiveFilename = archiveFilenameResolver(bucket, prefix);

      members = items.reduce((acc, item) => {
        const filename = resolveArchiveFilename(item);
        acc[filename] = item.signedUrl;
        return acc;
      }, {});
    }
  }

  return members;
}

/*
async function getDataURLFromDownload(bucket, path) {
  const { data, error } = await api.storage.from(bucket).download(path);
  if (error) {
    return null;
  } else {
    return await getDataURL(data);
  }
}

async function createDataURLs(bucket, paths, prefix) {

  const resolveArchiveFilename = archiveFilenameResolver(bucket, prefix);

  if (paths.length > 0) {
    const pending = paths.map((path) => getDataURLFromDownload(bucket, path));
    const completed = await Promise.all(pending);
    const data = zip(paths, completed);

    return data.reduce((acc, [path, url]) => {
      if (url) {
        const filename = resolveArchiveFilename(path);
        acc[filename] = url;
        return acc;
      }
    }, {});
  }
  return {};
}
*/

function createPublicURLs(bucket, paths, prefix) {
  const resolveArchiveFilename = archiveFilenameResolver(bucket, prefix);

  return paths.reduce((members, path) => {
    const {
      data: { publicUrl },
    } = api.storage.from(bucket).getPublicUrl(path);
    const filename = resolveArchiveFilename({ path });
    members[filename] = publicUrl;
    return members;
  }, {});
}

async function createDrawingFiles(group) {
  const { items } = group;

  /* TODO: generating the itemTagGenerator here means that duplicate marks will
     get mangled even if there are no duplicates when items are restricted to
     those with width and height set. This leads to "unnecessary" name
     mangling, but it ensures that item names will be consistent between this function
     and others e.g. createAttachments */
  const itemTag = itemTagGenerator(items);
  const pathMap = new Map();

  for (const id of items.order) {
    const { drawing } = items[id];
    if (drawing) {
      const path = `${drawing.id}/${drawing.name}`;
      const tag = itemTag(id);
      pathMap.set(path, tag);
    }
  }

  const paths = [...pathMap.keys()];
  const prefixer = (path) => `drawings/${pathMap.get(path)}`;

  return await createPublicURLs("drawings", paths, prefixer);
}

async function createAttachments(group) {
  const { attachments } = group;

  if (attachments) {
    const paths = attachments.map((a) => a.object_id);

    return await createSignedURLs("attachments", paths);
  }
}

async function createSummary(group, filename, single = false) {
  const prefix = single ? CORVELAY_PUBLIC_URL : CORVELAY_PRIVATE_URL;
  const jobId = group.job.id;
  const url = `${prefix}/jobs/${jobId}/summary.xlsx`;

  return {
    [filename]: url,
  };
}

async function createBOM(group, filename, single = false) {
  const prefix = single ? CORVELAY_PUBLIC_URL : CORVELAY_PRIVATE_URL;
  const jobId = group.job.id;
  const url = `${prefix}/jobs/${jobId}/bom.xlsx`;

  return {
    [filename]: url,
  };
}

async function createProductQuotePDF(jobId, filename, single = false) {
  const prefix = single ? CORVELAY_PUBLIC_URL : CORVELAY_PRIVATE_URL;
  const url = `${prefix}/jobs/${jobId}/product-quote.pdf`;

  return {
    [filename]: url,
  };
}

async function createPDF(group, _, __, filename, single = false) {
  const prefix = single ? CORVELAY_PUBLIC_URL : CORVELAY_PRIVATE_URL;
  const jobId = group.job.id;
  const url = `${prefix}/jobs/${jobId}/summary.pdf`;

  return {
    [filename]: url,
  };
}

/**
 *
 * @param {Blob} blob
 * @returns {Promise<string | ArrayBuffer>}
 */
async function getDataURL(blob) {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.addEventListener("loadend", () => resolve(reader.result));
    reader.addEventListener("error", () => reject(reader.error));
    reader.readAsDataURL(blob);
  });
}

export async function postArchive(archive) {
  const zipResponse = await fetch(`${VANDELAY_URL}/zip/archives`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(archive),
  });

  if (zipResponse.status == 201) {
    const location = zipResponse.headers.get("location");

    return `${VANDELAY_URL}${location}`;
  }

  return null;
}

/**
 *
 * @param {*} group
 * @param {*} types
 * @param {*} options
 */
export async function downloadJobBundle(
  group,
  types,
  productData,
  options,
  profile,
) {
  const {
    filename = "job",
    productQuote,
    rfq,
    includeSummary,
    includeData,
    includeCAD,
    includeBOM,
    includeAttachments,
  } = options;

  const featureFlags = createFeatureFlags(profile);

  const numRequests = count(identity, [
    includeSummary,
    includeData,
    includeCAD,
    includeAttachments,
    includeBOM,
  ]);

  let requests = [];

  if (includeSummary) {
    if (productQuote) {
      requests.push(
        createProductQuotePDF(
          group.job.id,
          `${filename}.pdf`,
          numRequests === 1,
        ),
      );
    } else {
      requests.push(
        createPDF(
          group,
          types,
          productData,
          `${filename}.pdf`,
          numRequests === 1,
        ),
      );
    }
  }
  if (includeData) {
    const name = `${filename} - Summary.xlsx`;
    const req = createSummary(group, name, numRequests === 1)
    requests.push(req);
  }
  if (includeCAD) {
    requests.push(createDXF(group));
  }

  if (includeBOM) {
    requests.push(
      createBOM(group, `${filename} - BOM.xlsx`, numRequests === 1),
    );
  }

  if (includeAttachments) {
    requests.push(createDrawingFiles(group));
    requests.push(createAttachments(group));
    /* Fabrications */
  }
  const responses = await Promise.all(requests);

  const [responseHead] = responses;
  const [fileHead, ...fileTail] = Object.keys(responseHead);

  /* Only create a zip file if there is more than one file */
  if (numRequests === 1 && fileTail.length === 0) {
    downloadURL(responseHead[fileHead], fileHead);
  } else if (numRequests > 0) {
    const members = responses.reduce((acc, members) => {
      acc = { ...members, ...acc };
      return acc;
    }, {});

    const downloadTarget = await postArchive({
      filename,
      members,
    });

    if (downloadTarget) {
      downloadURL(downloadTarget, filename);
    }
  }
}
